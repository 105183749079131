import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import * as _ from 'lodash'
import ContactFooter from "../components/ContactFooter";
import SidebarMenu from "../components/SidebarMenu";
import * as __ from '../utils';

export const ProcessPageTemplate = ({title, subtitle, image, cards, topSection, mainCards, contentComponent}) => {
    const PageContent = contentComponent || Content


    return (
        <React.Fragment>
            <div className={'process-page-container'}>
            <SidebarMenu/>

            <div className={'section mid-green-bg p-4'}>
                <div className={'container'}>
                    <h3 className={'font-white is-ligna-font'}>/ &nbsp;{title} &nbsp;/</h3>
                </div>
            </div>
            <div className={'section split-bg-light-top'}>
                <div className={'container'}>
                    <div className={'columns'}>
                        <div className={'column is-8'}>
                            <div className={'card'}>
                                <h4 className={'font-primary is-ligna-font'}>{topSection.title}</h4>
                                <hr/>
                                <p className={'text-first-para'}>{__.parse(topSection.firstPara)}</p>
                                <p className={'text'}>{__.parse(topSection.text)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {mainCards && mainCards.length && <div className={'section pb-5 split-bg-dark-top'}>
                <div className={'container'}>
                    <div className={'columns'}>
                        {_.map(mainCards, mainCard => {
                            return <div className={'column' + (mainCards.length > 1 ? ' is-4-desktop is-8' : ' is-8')}>
                                <div className={'card'}>
                                    <h4 className={'font-primary is-ligna-font'}>{mainCard.title}</h4>
                                    <hr/>

                                    <p className={'text-first-para'}>{__.parse(mainCard.firstPara)}</p>
                                    <p className={'text'}>{__.parse(mainCard.text)}</p>
                                </div>
                            </div>
                        })}

                    </div>
                </div>
            </div>}
            <SidebarMenu mobile={true}/>

            </div>
            <ContactFooter borderTop={!(mainCards && mainCards.length)}/>
        </React.Fragment>
    )
}

ProcessPageTemplate.propTypes = {
    // title: PropTypes.string.isRequired,
    // content: PropTypes.string,
    // contentComponent: PropTypes.func,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

const ProcessPage = ({data}) => {
    // const {markdownRemark: post} = data
    const {frontmatter} = data.markdownRemark

    return (
        <Layout>
            <ProcessPageTemplate
                contentComponent={HTMLContent}
                title={frontmatter.title}
                subtitle={frontmatter.subtitle}
                image={frontmatter.image}
                cards={frontmatter.cards}
                topSection={frontmatter.topSection}
                mainCards={frontmatter.mainCards}
            />
        </Layout>
    )
}

ProcessPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ProcessPage

export const ProcessPageQuery = graphql`
  query ProcessPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cards {
            title
            icon
            text
        }
        topSection {
            title
            firstPara
            text
        }
        mainCards {
            title
            firstPara
            text
        }
      }
    }
  }
`
