import React from 'react'
import {Link} from 'gatsby'


const SidebarMenu = class extends React.Component {
    render() {
        return <div className={'sidebar-menu' + (!this.props.mobile ? ' is-hidden-mobile':' is-mobile is-hidden-tablet mt-4')}>
            <div className={'container'}>
                <div className={'columns m-0'}>
                    <div className={'column is-4'}>
                        <div className={'card border-primary p-3'}>
                            <Link className={'text-link'}
                                  activeClassName={'current-item'}
                                  to={'/trees-and-development'}>
                                <h4 className={'font-primary has-text-weight-semibold title-spacing'}>Trees & Development</h4></Link>

                            <hr className={'mt-2 mb-3'}/>

                            <h5 className={'is-italic'}>Stage 1</h5>

                            <Link className={'text-link is-block'}
                                  partiallyActive={true}
                                  activeClassName={'current-item'}
                                  to={'/trees-and-development/bs-5837-tree-survey'}>BS 5837 Tree Survey</Link>
                            <Link className={'text-link is-block'}
                                  partiallyActive={true}
                                  activeClassName={'current-item'}
                                  to={'/trees-and-development/pre-design-advice'}>Pre-design Advice</Link>
                            <div className={'mb-2'}></div>

                            <h5 className={'is-italic'}>Stage 2</h5>
                            <Link className={'text-link is-block'}
                                  partiallyActive={true}
                                  activeClassName={'current-item'}
                                  to={'/trees-and-development/impact-assessment'}>Impact Assessment</Link>
                            <Link className={'text-link is-block'}
                                  partiallyActive={true}
                                  activeClassName={'current-item'}
                                  to={'/trees-and-development/tree-protection-scheme'}>Tree Protection Scheme</Link>
                            <div className={'mb-2'}></div>
                            <h5 className={'is-italic'}>Stage 3</h5>
                            <Link className={'text-link is-block'}
                                  partiallyActive={true}
                                  activeClassName={'current-item'}
                                  to={'/trees-and-development/arboricultural-supervision'}>Arboricultural Supervision</Link>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default SidebarMenu
